<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getOperationLockRegistry.name"
                    :avatarData="getOperationLockRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getOperationLockRegistry.name"
                    :items="getOperationLockTable.items"
                    :headers="headers"
                    :length="getOperationLockTable.pages"
                    :page="getOperationLockTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getOperationLockSearchFields.searchFields"
                    :loading="getOperationLockTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getOperationLockTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('operation-lock:addingOperationLock')"
                    :open="open"
                    :height="'560'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addOperationLock()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('operation-lock:addOperationLock') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Operation-lock/Table/Columns'
import AEContent from './../../../components/Registries/Operation-lock/Modal/Content'
import Buttons from './../../../components/Registries/Operation-lock/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('operation-lock:index'),
                    value: 'index',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('operation-lock:createdAt'),
                    value: 'createdAt',
                    width: '10%',
                },
                {
                    text: this.$t('operation-lock:contractor'),
                    value: 'contractor.name',
                    width: '30%',
                    showSearch: true,
                },
                {
                    text: this.$t('operation-lock:updatedAt'),
                    value: 'updatedAt',
                    width: '15%',
                },
                {
                    text: this.$t('operation-lock:responsible'),
                    value: 'responsible.name join responsible.lastname',
                    width: '14%',
                    showSearch: true,
                },
                {
                    text: this.$t('operation-lock:reason'),
                    value: 'reason.field',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('operation-lock:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getOperationLockRegistry',
            'getOperationLockTable',
            'getOperationLockSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchOperationLockTable',
            'fetchAllDepartments',
            'fetchUsers',
        ]),
        ...mapMutations([
            'clearOperationLockModal',
            'clearOperationLockErrors',
            'setOperationLockTable',
            'clearOperationLockTable',
            'setOperationLockSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            // this.clearCarsErrors()
            this.open = false
        },
        async addOperationLock() {
            await void this.fetchAllDepartments({ select: 'users' })
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearOperationLockErrors()
            this.clearOperationLockModal()
        },
        setSearchFields(searchFields) {
            this.setOperationLockSearch(searchFields)
        },
        setSearch(search) {
            this.setOperationLockTable({ search, page: 1 })
            this.fetchOperationLockTable()
        },
        setPage(page) {
            this.setOperationLockTable({ page })
            this.fetchOperationLockTable()
        },
        setSort(sort) {
            this.setOperationLockTable({ sort })
            this.fetchOperationLockTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearOperationLockTable()
            this.fetchOperationLockTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/operation-lock/:id')
            store.commit('clearOperationLockTable')
        store.dispatch('fetchOperationLockTable').then(() => next())
    },
}
</script>
