var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getOperationLockErrors.step),callback:function ($$v) {_vm.$set(_vm.getOperationLockErrors, "step", $$v)},expression:"getOperationLockErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getOperationLockErrors.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('operation-lock:basicInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('operation-lock:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'operation-lock:contractor'
                                        )}*`,"hint":"Zacznij pisać...","hide-no-data":_vm.hideContacts,"items":_vm.getContactsInput.items,"item-text":"name","item-value":"_id","return-object":"","error-messages":_vm.getOperationLockErrors.stepOne
                                                .fields.contractor},on:{"keyup":function($event){return _vm.setContacts($event.target.value)},"input":function($event){return _vm.assignID()}},model:{value:(
                                            _vm.getOperationLockModal.contractor
                                        ),callback:function ($$v) {_vm.$set(_vm.getOperationLockModal, "contractor", $$v)},expression:"\n                                            getOperationLockModal.contractor\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"box-autocomplete",attrs:{"label":`${_vm.$t(
                                            'operation-lock:responsible'
                                        )}*`,"item-value":"name","items":_vm.getUsersList,"return-object":"","multiple":"","filter":_vm.filter,"error-messages":_vm.getOperationLockErrors.stepOne
                                                .fields.responsible},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(` ${data.item.name} ${data.item.lastname},`)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mx-2",attrs:{"size":"35","color":"secondary"}},[(
                                                            !data.item
                                                                .picture
                                                        )?_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(_vm.avatarNick( data.item .name, data.item .lastname ))+" ")]):_c('img',{attrs:{"src":`${data.item.picture}`}})])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.lastname)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.job)+" ")])],1)]}}]),model:{value:(
                                            _vm.getOperationLockModal.responsible
                                        ),callback:function ($$v) {_vm.$set(_vm.getOperationLockModal, "responsible", $$v)},expression:"\n                                            getOperationLockModal.responsible\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.reason
                                                ? _vm.getRegistryDictionaries.reason
                                                : [],"dense":"","label":`${_vm.$t(
                                            'operation-lock:reason'
                                        )}*`,"return-object":"","item-value":"field","error-messages":_vm.getOperationLockErrors.stepOne
                                                .fields.reason},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getOperationLockModal.reason
                                        ),callback:function ($$v) {_vm.$set(_vm.getOperationLockModal, "reason", $$v)},expression:"\n                                            getOperationLockModal.reason\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12 mt-10"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'operation-lock:description'
                                        )}`,"rows":"1","auto-grow":"","no-resize":""},model:{value:(
                                            _vm.getOperationLockModal.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getOperationLockModal, "description", $$v)},expression:"\n                                            getOperationLockModal.description\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"textGrey--text",attrs:{"small":"","elevation":"0","color":"white"},on:{"click":function($event){return _vm.toMe()}}},on),[_c('v-icon',{attrs:{"color":"textGrey","size":"35"}},[_vm._v("mdi-account")]),_vm._v(_vm._s(_vm.$t('service-info:toMe')))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('service-info:toMe')))])])],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"textGrey--text",attrs:{"small":"","elevation":"0","color":"white"},on:{"click":function($event){return _vm.toAllUsers()}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"textGrey","size":"35"}},[_vm._v("mdi-account-group")]),_vm._v(_vm._s(_vm.$t( 'service-info:allUsers' )))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('service-info:allUsers')))])])],1),_c('v-col',{attrs:{"cols":"12","offset":"1","md":"7"}},[_c('v-autocomplete',{staticClass:"box-autocomplete",attrs:{"data-cy":"assignTo","label":_vm.$t('service-info:infoTo') + '*',"item-value":"name","items":_vm.value === 'users'
                                                ? _vm.getUsersList
                                                : _vm.getAllDepartments,"error-messages":_vm.getOperationLockErrors.stepOne
                                                .fields.to,"return-object":"","multiple":"","filter":_vm.filter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(_vm.value === 'users')?_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(` ${data.item.name} ${data.item.lastname},`)+" ")]):_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(`${data.item.name},`)+" ")])]}},{key:"item",fn:function(data){return [(_vm.value === 'users')?_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mx-2",attrs:{"size":"35","color":"secondary"}},[(
                                                            !data.item
                                                                .picture
                                                        )?_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(_vm.avatarNick( data.item .name, data.item .lastname ))+" ")]):_c('img',{attrs:{"src":`${data.item.picture}`}})])],1):_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"35","color":"#445D75"}},[_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(data.item.shortName.toUpperCase())+" ")])])],1),(_vm.value === 'users')?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.lastname)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.job)+" ")])],1):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}}]),model:{value:(
                                            _vm.getOperationLockModal.to[_vm.value]
                                        ),callback:function ($$v) {_vm.$set(_vm.getOperationLockModal.to, _vm.value, $$v)},expression:"\n                                            getOperationLockModal.to[value]\n                                        "}},[_c('v-tabs',{staticClass:"elevation-1 select--fields",attrs:{"slot":"prepend-item","centered":""},slot:"prepend-item"},[_c('v-tab',{on:{"click":function($event){_vm.value = 'users'}}},[_vm._v(_vm._s(_vm.$t('service-info:users')))]),_c('v-tab',{on:{"click":function($event){_vm.value = 'departments'}}},[_vm._v(_vm._s(_vm.$t( 'service-info:departments' )))])],1)],1)],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }