<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getOperationLockErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getOperationLockErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('operation-lock:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t('operation-lock:basicInfo')
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-combobox
                                            v-model="
                                                getOperationLockModal.contractor
                                            "
                                            :label="`${$t(
                                                'operation-lock:contractor'
                                            )}*`"
                                            hint="Zacznij pisać..."
                                            @keyup="
                                                setContacts($event.target.value)
                                            "
                                            @input="assignID()"
                                            :hide-no-data="hideContacts"
                                            :items="getContactsInput.items"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getOperationLockErrors.stepOne
                                                    .fields.contractor
                                            "
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            :label="`${$t(
                                                'operation-lock:responsible'
                                            )}*`"
                                            item-value="name"
                                            :items="getUsersList"
                                            return-object
                                            v-model="
                                                getOperationLockModal.responsible
                                            "
                                            multiple
                                            class="box-autocomplete"
                                            :filter="filter"
                                            :error-messages="
                                                getOperationLockErrors.stepOne
                                                    .fields.responsible
                                            "
                                        >
                                            <template v-slot:selection="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar>
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="
                                                getOperationLockModal.reason
                                            "
                                            :items="
                                                getRegistryDictionaries.reason
                                                    ? getRegistryDictionaries.reason
                                                    : []
                                            "
                                            dense
                                            :label="`${$t(
                                                'operation-lock:reason'
                                            )}*`"
                                            return-object
                                            item-value="field"
                                            :error-messages="
                                                getOperationLockErrors.stepOne
                                                    .fields.reason
                                            "
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12 mt-10">
                                        <v-textarea
                                            :label="`${$t(
                                                'operation-lock:description'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="
                                                getOperationLockModal.description
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="toMe()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        size="35"
                                                        >mdi-account</v-icon
                                                    >{{
                                                        $t('service-info:toMe')
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{
                                                $t('service-info:toMe')
                                            }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    v-on="on"
                                                    elevation="0"
                                                    @click="toAllUsers()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        class="mr-2"
                                                        size="35"
                                                        >mdi-account-group</v-icon
                                                    >{{
                                                        $t(
                                                            'service-info:allUsers'
                                                        )
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{
                                                $t('service-info:allUsers')
                                            }}</span>
                                        </v-tooltip></v-col
                                    >

                                    <v-col cols="12" offset="1" md="7">
                                        <v-autocomplete
                                            data-cy="assignTo"
                                            :label="
                                                $t('service-info:infoTo') + '*'
                                            "
                                            item-value="name"
                                            :items="
                                                value === 'users'
                                                    ? getUsersList
                                                    : getAllDepartments
                                            "
                                            :error-messages="
                                                getOperationLockErrors.stepOne
                                                    .fields.to
                                            "
                                            return-object
                                            v-model="
                                                getOperationLockModal.to[value]
                                            "
                                            multiple
                                            class="box-autocomplete"
                                            :filter="filter"
                                        >
                                            <template v-slot:selection="data">
                                                <div
                                                    v-if="value === 'users'"
                                                    class="mr-1"
                                                >
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                                <div class="mr-1" v-else>
                                                    {{ `${data.item.name},` }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar
                                                    v-if="value === 'users'"
                                                >
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-avatar v-else>
                                                    <v-avatar
                                                        size="35"
                                                        color="#445D75"
                                                    >
                                                        <span
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                data.item.shortName.toUpperCase()
                                                            }}
                                                        </span>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content
                                                    v-if="value === 'users'"
                                                >
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <!-- === DEPARTMENTS === -->
                                                <v-list-item-content v-else>
                                                    <v-list-item-title>
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-tabs
                                                slot="prepend-item"
                                                centered
                                                class="elevation-1 select--fields"
                                            >
                                                <v-tab
                                                    @click="value = 'users'"
                                                    >{{
                                                        $t('service-info:users')
                                                    }}</v-tab
                                                >
                                                <v-tab
                                                    @click="
                                                        value = 'departments'
                                                    "
                                                    >{{
                                                        $t(
                                                            'service-info:departments'
                                                        )
                                                    }}</v-tab
                                                >
                                            </v-tabs>
                                        </v-autocomplete></v-col
                                    >
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            value: 'users',
            editable: true,
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
        ]),
        ...mapMutations(['setContactsInput']),
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        assignID() {
            if (
                this.getOperationLockModal.contractor &&
                this.getOperationLockModal.contractor._id
            ) {
                this.getOperationLockModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getOperationLockModal.contractor._id,
                })
            }
        },
        toAllUsers() {
            this.getOperationLockModal.to.users = this.getUsersList
        },
        toMe() {
            this.getOperationLockModal.to.users = [
                {
                    _id: this.getProfileDetails._id,
                    name: this.getProfileDetails.name,
                    lastname: this.getProfileDetails.lastname,
                    mail: this.getProfileDetails.mail,
                },
            ]
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getOperationLockModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                )
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase())
            } else
                return item.name.toLowerCase().includes(queryText.toLowerCase())
        },
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0]
            return nick.toUpperCase()
        },
    },
    computed: {
        ...mapGetters([
            'getUsersList',
            'getProfileDetails',
            'getOperationLockModal',
            'getOperationLockErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getAllDepartments',
        ]),
    },
    mounted() {
        this.getUsersList.sort((a, b) => {
            if (a.lastname < b.lastname) return -1
            if (a.lastname > b.lastname) return 1
            return 0
        })
    },
}
</script>
